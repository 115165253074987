import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDBox from '../../components/MDBox';
import Grid from '@mui/material/Grid';
import MDInput from '../../components/MDInput';
import Card from '@mui/material/Card';
import DataTable from 'react-data-table-component';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import { useEffect, useState } from 'react';
import { URL_WITH_VERSION } from '../../shared';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { Button, Modal } from 'antd';
import { PDFRenderer, PNGRenderer } from 'react-doc-viewer';
import DownloadIcon from '@mui/icons-material/Download';
import JSZip from 'jszip';
import ProgressBar from '@ramonak/react-progress-bar';

export  default function  Attachments(props) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDownlaoding, setIsDownlaoding] = useState(false);
  const [customLable, setCustomLable] = useState("0 Is Files Downloaded");
  const [compleated, setCompleated] = useState(1);

  const [urls, setUrls] = useState([]);


  const [columns, setColumns] = useState([
    {
      name :"Company Name",
      width:"250px",

      cell : (row)=>{
        return <>
          {row.company_name}
        </>
      }
    },
    {
      name : "Document ID",
      width:"250px",
      cell : (row)=>{
        return  <>
          {row._id}
        </>
      }
    },
    {
      name : "Total  No OF Documents",
      width:"100px",

      cell : (row)=>{
        return  <>
          {row.total_files}
        </>
      }
    },
    {
      name : "File Names",
      width:"500px",
      cell : (row)=>{
        return  <>
          {
            <FilenameChips row={row}/>
          }
        </>
      }
    }
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [text,setText] = useState('');
  const fetchData = async (page) => {
    setLoading(true);
    let url  = data.next

    if(page > currentPage){
      url =  data.next
    }
    else{
      url = data.previous
    }
    if(url != null){
      url = url.replace("http", "https")

    }
    setCurrentPage(page)
    let response =  await fetch(url, {
      method: 'GET',
      "content-type": "application/json",
    })
    response = await response.json();
    setData(response);
    setLoading(false);


  }

  async function fetchAttachments(q) {
    let  url  = URL_WITH_VERSION+"accounts/attachments"
    if(q){
      url =  url + "?filename="+q
    }
    let response  =  await fetch(url,{
      method: 'GET',
      "content-type": "application/json",
    });
    let data  =  await response.json()
    console.log(data)
    setData(data)
    setLoading(false);

    return data
  }


  useEffect(() => {

    fetchAttachments();


  },[])


  function handlePageChange(page, perPage) {
    fetchData(page)
    console.log('page', page, perPage)
  }

  return (
    <>

      <DashboardLayout>
        <DashboardNavbar />
        {isDownlaoding ?<>
          <ProgressBar  bgColor={"black"} customLabel={`${compleated} files Downloaded and remaining Files Are ${urls.length - compleated}`}   height={"50px"} completed={compleated} maxCompleted={urls.length} />
        </>  : <></> }

        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value)
                    fetchAttachments(e.target.value);
                  }}

                />
              </div>
              {/*<Button  onClick={()=>console.log("ji")}>*/}
              {!isDownlaoding ? <>

                <DownloadIcon width={"120px"} height={"120px"}  onClick={async ()=>{
                  setIsDownlaoding(true);
                  let zip = new JSZip()


                  let url = [
                  ]
                  console.log(data)
                  let d = data.results

                  for(let i = 0 ; i <d.length;i++){
                    let companyName_attachments  = d[i].company_name;
                    let doc_id  = d[i]._id;
                    let response = await fetch(URL_WITH_VERSION + `accounts/mails/${doc_id}/${companyName_attachments}`, {
                      method: 'GET',
                      "content-type": "application/json",
                    })
                    let responseData  =  await response.json()
                    let clinet  =  responseData.client
                    let message_id  =  responseData.message_id
                    let Attachments = responseData.Attachments
                    for(let j = 0 ; j <Attachments?.length; j++){
                      let  u = `https://files.theoceann.com/view_attachment?email=${clinet}&attachment_id=${Attachments[j].attachment_id}&GmailMessageId=${message_id}&file_name=${Attachments[j].filename}`
                      url.push({
                        url:u,
                        filename:Attachments[j].filename
                      });
                    }
                  }

                  setUrls(url)


                  for(let i = 0 ; i<url.length;i++) {
                    try {
                      let resBlob = await fetch(url[i].url, { method: 'GET', })
                      if(resBlob.status === 200){
                        setCompleated((count)=>count +1)


                        setCustomLable((ele)=> `${compleated} is Downloaded..`)
                        resBlob = await resBlob.blob()
                        zip.file(url[i].filename, resBlob)
                      }
                    }
                    catch (e) {
                      console.log(e)
                    }

                  }



                  setCustomLable("Create ing  ZIP FIle")
                  console.log(compleated)

                  zip.generateAsync({type:"blob"})
                    .then(function(content) {

                      setCustomLable("Packing All Files Into The Zip")
                      // see FileSaver.js
                      console.log(content)
                      const url = URL.createObjectURL(content)
                      setCompleated(1000)
                      window.open(url, "_blank");
                    });
                  setIsDownlaoding(false);
                  setUrls([])

                  // alert("Hi")
                  // for (let i = 0; i < url.length; i++) {
                  //   let resBlob = await fetch(url[i], { method: 'GET', })
                  //   resBlob = await resBlob.blob()
                  //   zip.folder("content")
                  //   zip.file("file.pdf", resBlob)
                  //
                  //   zip.generateAsync({type:"blob"})
                  //     .then(function(content) {
                  //       // see FileSaver.js
                  //       console.log(content)
                  //       const url = URL.createObjectURL(content)
                  //       window.open(url, "_blank");
                  //     });
                  // }




                }} />


              </>: <></>}
              {/*</Button>*/}
              <br />
              <br />
              <Card>



                <MDBox>

                  <DataTable
                    paginationTotalRows={data.count}
                    data={data.results}
                    columns={columns}
                    rows={data}
                    pagination
                    pageSize={10}
                    paginationServer={true}
                    onChangePage={handlePageChange}
                    progressPending={loading}
                    progressComponent={<CircularProgress loading={loading} />}
                  />
                </MDBox>
              </Card>

            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  )
}

// eslint-disable-next-line react/prop-types
const FilenameChips = ({ row }) => {
  const [data, setData] = useState([]);
  const [isAttachmentIdsAvailable, setIsAttachmentIdsAvailable] = useState(false);
  const [docs, setDocs] = useState([]);
  const fetchCurrentFile  = (Adata, fileName)=>{
    for(let  i = 0 ; i < Adata.length ; i++){
      if(Adata[i].filename === fileName){
        return Adata[i]
      }

    }
    return null;
  }
  const getFileIcon = (filename) => {
    if (filename.endsWith('.xlsx') || filename.endsWith('.xls')) {
      return "📊";
    } else if (filename.endsWith('.png') || filename.endsWith('.jpg')) {
      return "🖼️";
    } else if (filename.endsWith('.pdf')) {
      return "📄";
    } else {
      return "📁";
    }
  };
  return (
    <div  style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      borderRadius: '10px',
      padding: '10px',
      gap: '10px',
      alignContent: 'center',
      flexGrow: 1,



    }}>
    {/*<Stack*/}
    {/*  direction="row"*/}
    {/*  spacing={1}*/}
    {/*  flexWrap="wrap"*/}
    {/*  sx={{ gap: 1 }}*/}
    {/*>*/}
      {/* eslint-disable-next-line react/prop-types */}
      {row.filenames.map((element, i) => (
        <div key={i}>
        <Chip
          key={i}
          label={`${getFileIcon(element)} ${element}`}
          onClick={async () => {

            let Attachments =  data;
            if(!isAttachmentIdsAvailable) {
              // eslint-disable-next-line react/prop-types
              let response = await fetch(URL_WITH_VERSION + `accounts/mails/${row._id}/${row.company_name}`, {
                method: 'GET',
                "content-type": "application/json",
              })
              let responseData  =  await response.json()
              console.log(responseData)
              setData(responseData)
              setIsAttachmentIdsAvailable(true)
              Attachments = responseData

            }

            let files  =  Attachments.Attachments;
            let currentFIle  =  fetchCurrentFile(files,element)
            console.log(currentFIle)
            let client  =  Attachments.client
            // eslint-disable-next-line react/prop-types
            console.log(row._id)
            if(currentFIle != null) {
              let l = new URL("https://files.theoceann.com/view_attachment")
              l.searchParams.set("email",client);
              l.searchParams.set("attachment_id",currentFIle.attachment_id);
              l.searchParams.set("GmailMessageId",currentFIle.attachment_id)  
              l.searchParams.set("file_name",element);

              l = `https://files.theoceann.com/view_attachment?email=${client}&attachment_id=${currentFIle.attachment_id}&GmailMessageId=${Attachments.message_id}&file_name=${element}`
              if(element.endsWith("pdf") || element.endsWith("docx")){
                setDocs([l])
              }
              else {
                window.open(l)
              }


            }












            }}
          sx={{
            '&:hover': {
              backgroundColor: '#e0e0e0',
              transform: 'translateY(-2px)',
              transition: 'transform 0.2s, background-color 0.2s',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }
          }}
        />
          {docs.length > 0 ?<>
            <Modal  width="70%"  open={docs.length > 0} onOk={()=>console.log("click")} onCancel={()=>setDocs([])}>
              {/* Modal content */}
              {/*<DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />*/}
              {/*<DocViewer documents={docs}     pluginRenderers={[PDFRenderer, PNGRenderer]}*/}
<div style={{height:'700px'}}>

  <iframe width={"100%"} height={"100%"} src={docs[0]} />

</div>





            </Modal>



          </>  : <></>}
        </div>
      ))
      }
    {/*</Stack>*/}
    </div>
  );
};









